<template>
    <div
        class="flex items-start mt-3"
        :ref="`message_${messageItem.message_uid}`"
        :id="`message_${ messageItem.message_uid }`">
        <div
            class="flex justify-between w-full"
            :class="myMessage && 'flex-row-reverse'">
            <a-dropdown
                :trigger="isMobile ? [] : ['contextmenu']"
                class="message_context"
                @visibleChange="visibleChange"
                :disabled="isViewContext">
                <div
                    class="flex"
                    :class="myMessage && 'flex-row-reverse'">
                    <div
                        v-if="messageItem.message_author"
                        :class="myMessage ? 'ml-1 lg:ml-3' : 'mr-1 lg:mr-3'">
                        <a-avatar
                            v-if="authorAvatar"
                            :key="authorId"
                            :size="windowWidth > 864 ? 34 : 28"
                            :src="authorAvatar" />
                        <a-avatar
                            v-else
                            :size="windowWidth > 864 ? 34 : 28">
                            {{ avatarText }}
                        </a-avatar>
                    </div>
                    <div
                        class="bubble"
                        :ref="`mb_${messageItem.message_uid}`"
                        :class="[bubbleBackground, isDelete, textLength && 'large_message', expand && 'show', isMobile && 'mobile', messageItem.is_system && 'is_system']"
                        v-touch:longtap="longtapHandler">
                        <div
                            class="mb-1 user_name text-xs flex item-center font-semibold">
                            <template v-if="messageItem.message_author">
                                {{messageItem.message_author.full_name}}
                            </template>
                            <template v-if="messageItem.is_system">
                                {{$t('chat.systemic')}}
                            </template>
                        </div>
                        <SharedObject   
                            v-if="!messageItem.is_deleted && messageItem.share"
                            :message="messageItem" />
                       
                        <div
                            class="cursor-pointer reply_message truncate w-full mt-2 mb-2 pt-1 pb-1 pr-2"
                            v-if=" messageItem.message_reply && !messageItem.is_deleted"
                            @click="messSearch(messageItem.message_reply)">
                            <template v-if="messageItem.message_reply.message_author">
                                <div class="reply_chat_author truncate">
                                    {{messageItem.message_reply.message_author.full_name}}
                                    {{messageItem.message_reply.message_author.id === activeChat.chat_author.id ? ` - ${$t('chat.owner')}` : ''}}
                                </div>
                            </template>
                            <template v-if="messageItem.message_reply.text.length">
                                <div
                                    class="reply_text text-sm truncate"
                                    v-html="messageItem.message_reply.text">
                                </div>
                            </template>
                            <template v-else>
                                <template v-if="messageItem.message_reply.share">
                                    <div class="reply_text text-sm">
                                        {{$t(messageItem.message_reply.share.model)}}
                                    </div>
                                </template>
                                <div
                                    v-else
                                    class="reply_text text-sm">
                                    {{$t('chat.file_and_image')}}
                                </div>
                            </template>
                        </div>
                        <div
                            class="message"
                            v-html="textReplace">
                        </div>
                        <div
                            v-if="textLength"
                            class="text_expand">
                            <div
                                class="btn"
                                @click="toggleShow()">
                                {{ expandText }}
                            </div>
                        </div>

                        <ChatMessageFiles
                            v-if="showFiles"
                            :message="messageItem" />

                        <div class="flex items-center lg:justify-end text-xs mt-1 lg:mt-0">

                            <component 
                                :ref="`mobile_menu_${messageItem.message_uid}`"
                                :is="menuComponent" 
                                :messageItem="messageItem"
                                :deleteBtnShow="deleteBtnShow"
                                :pinMessageOn="pinMessageOn"
                                :pinMessageShow="pinMessageShow"
                                :replyMethod="replyMethod"
                                :createTask="createTask"
                                :pinMessage="pinMessage"
                                :copyMessage="copyMessage"
                                :isScrolling="isScrolling"
                                :deleteMessage="deleteMessage"
                                :unpinMessage="unpinMessage"
                                :openOrderDrawer="createOrder"
                                :messSearch="messSearch"
                                :messageRef="$refs[`mb_${this.messageItem.message_uid}`]" />

                            <span class="flex items-center">
                                {{messageDate}}
                            </span>
                            <div
                                v-if="windowWidth > 864 && menuLoading"
                                class="ml-3">
                                <a-spin size="small" />
                            </div>
                        </div>
                    </div>
                </div>
                <a-menu slot="overlay">
                    <a-menu-item key="loading" v-if="actionLoading">
                        <a-spin size="small" />
                    </a-menu-item>
                    <template v-else>
                        <template v-if="actions">
                            <template v-if="!pinMessageOn">
                                <a-menu-item
                                    v-if="actions.reply && actions.reply.availability"
                                    key="1"
                                    class="flex items-center"
                                    @click="replyMethod()">
                                    <i class="fi fi-rr-comment mr-2"></i>
                                    {{$t('chat.to_answer')}}
                                </a-menu-item>
                                <a-menu-item
                                    v-if="actions.add_task && actions.add_task.availability"
                                    key="3"
                                    class="flex items-center"
                                    @click="createTask()">
                                    <i class="fi fi-rr-checkbox mr-2"></i>
                                    {{$t('chat.set_task')}}
                                </a-menu-item>
                                <a-menu-item
                                    v-if="actions.add_order && actions.add_order.availability && messageItem.message_author && user && messageItem.message_author.id !== user.id"
                                    key="8"
                                    class="flex items-center"
                                    @click="createOrder()">
                                    <i class="fi fi-rr-shopping-cart-check mr-2"></i>
                                    {{$t('chat.set_order')}}
                                </a-menu-item>
                                <!-- <a-menu-item
                                    v-if="!myMessage && activeChat.is_public"
                                    key="4"
                                    @click="sendMessage()">
                                    {{$t('chat.personal_chat')}}
                                </a-menu-item> -->
                                <a-menu-item
                                    v-if="actions.pin && actions.pin.availability && pinMessageShow && !messageItem.is_pinned"
                                    key="5"
                                    class="flex items-center"
                                    @click="pinMessage()">
                                    <i class="fi fi-rr-thumbtack mr-2"></i>
                                    {{$t('chat.anchor')}}
                                </a-menu-item>
                                <a-menu-item
                                    v-if="actions.copy_text && actions.copy_text.availability && messageItem.text"
                                    key="9"
                                    class="flex items-center"
                                    @click="copyMessage()">
                                    <i class="fi fi-rr-copy mr-2"></i>
                                    {{$t('chat.copy_to_clipboard')}}
                                </a-menu-item>
                            </template>
                            <template v-if="actions.pin && actions.pin.availability && messageItem.is_pinned">
                                <a-menu-item
                                    v-if="pinMessageShow"
                                    key="6"
                                    class="flex items-center"
                                    @click="unpinMessage()">
                                    <i class="fi fi-rr-comment-xmark mr-2"></i>
                                    {{$t('chat.unpin')}}
                                </a-menu-item>
                                <a-menu-item
                                    v-if="pinMessageOn"
                                    key="7"
                                    class="flex items-center"
                                    @click="messSearch(messageItem)">
                                    <i class="fi fi-rr-comments mr-2"></i>
                                    {{$t('chat.show_in_chat')}}
                                </a-menu-item>
                            </template>
                            <a-menu-item
                                key="2"
                                class="text-red-500 flex items-center"
                                v-if="deleteBtnShow"
                                @click="deleteMessage()">
                                <i class="fi fi-rr-trash mr-2"></i>
                                {{$t('chat.remove')}}
                            </a-menu-item>
                        </template>
                    </template>
                </a-menu>
            </a-dropdown>
        </div>

        <component
            :is="orderDriwer"
            page_name="crm.order_create_page_chat"
            :injectContractor="injectContractor"
            :injectContractorFilter="injectContractorFilter"
            ref="orderDrawer" />
    </div>
</template>

<script>
import ChatMessageFiles from './ChatMessageFiles'
import SharedObject from './messages/SharedObject'
import eventBus from '@/utils/eventBus'
import ChatEventBus from '../../utils/ChatEventBus'
import { mapMutations, mapState } from 'vuex'
import computedMixin from './computed'
export default {
    name: "ChatMessage",
    components: {
        ChatMessageFiles,
        SharedObject
    },
    mixins: [computedMixin],
    props: {
        messageItem: {
            type: Object,
            require: true
        },
        user: {
            type: Object,
            required: false
        },
        replySearch: {
            type: Function,
            default: () => {}
        },
        pinMessageOn: {
            type: Boolean,
            default: false
        },
        isScrolling: {
            type: Boolean,
            default: false
        },
        resizeEvent: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        ...mapState({
            config: state => state.config.config
        }),
        authorAvatar() {
            const avatar = this.messageItem.message_author?.avatar
            return avatar?.path || avatar
        },
        authorId() {
            return this.messageItem?.message_author.id
        },
        orderDriwer() {
            return () => import('@apps/Orders/views/CreateOrder/OrderDrawer.vue')
        },
        showFiles() {
            return this.messageItem?.attachments?.length && !this.messageItem.is_deleted
        },
        avatarText() {
            const n = this.messageItem.message_author.full_name.split(' ')
            return `${n[0].charAt(0).toUpperCase()}${n[1] ? n[1].charAt(0).toUpperCase() : ''}${n[2] ? n[2].charAt(0).toUpperCase() : ''}`
        }
    },
    data() {
        return {
            expand: false,
            menuLoading: false,
            expandText: this.$t('chat.uncover'),
            visible: false,
            actions: null,
            actionLoading: false,
            injectContractor: {},
            injectContractorFilter: {}
        }
    },
    methods: {
        ...mapMutations({
            setReplyMessage: 'chat/setReplyMessage',
            setPin: 'chat/PIN_MESSAGE',
            setUnpin: 'chat/UNPIN_MESSAGE',
            removeMessage: 'chat/removeMessage'
        }),
        copyMessage() {
            try {
                navigator.clipboard.writeText(this.messageItem.text)
                this.$message.info('Сообщение скопировано')
            } catch(e) {
                console.log(e)
                this.$message.error('Ошибка копирования')
            }
        },
        longtapHandler() {
            if(!this.messageItem.is_system) {
                this.$nextTick(() => {
                    this.$refs[`mobile_menu_${this.messageItem.message_uid}`].openMobileMenu()
                })
            }
        },
        async visibleChange(vis) {
            if(vis) {
                try {
                    this.actionLoading = true
                    const { data } = await this.$http.get('/chat/message/action_info/', {
                        params: {
                            message: this.messageItem.message_uid,
                            chat: this.messageItem.chat || this.messageItem.chat_uid
                        }
                    })
                    if(data?.actions) {
                        this.actions = data.actions
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.actionLoading = false
                }
            }
        },
        replyMethod() {
            this.setReplyMessage({
                id: this.activeChat.chat_uid,
                mesage: this.messageItem
            })
            ChatEventBus.$emit('inputFocus')
            ChatEventBus.$emit('arreaScroll')
        },
        messSearch(mess) {
            this.menuLoading = true
            this.replySearch(mess)
                .then(() => {
                    ChatEventBus.$emit('SLIDE_TO_PIN', mess)
                    this.menuLoading = false
                })
        },
        async pinMessage() {
            try {
                this.menuLoading = true
                let data = this.messageItem

                data.chat_uid = this.messageItem.chat_uid ? this.messageItem.chat_uid : this.messageItem.chat
              
                this.$socket.client.emit("chat_pin_message", data)
                this.setPin(data)
                ChatEventBus.$emit('PINNED_MESSAGE', data)
            } catch(e) {
                console.log(e)
                this.$mess.error(this.$t('error'))
            } finally {
                this.menuLoading = false
            }
        },
        async unpinMessage() {
            try {
                this.menuLoading = true
                let data = this.messageItem
                
                data.chat_uid = this.messageItem.chat_uid ? this.messageItem.chat_uid : this.messageItem.chat

                this.$socket.client.emit("chat_unpin_message", this.messageItem)
                this.setUnpin(data)
            } catch(e) {
                console.log(e)
                this.$message.error(this.$t('error'))
            } finally {
                this.menuLoading = false
            }
        },
        async sendMessage() {
            try{ 
                this.menuLoading = true
                const res = await this.$store.dispatch('chat/getUserChat', this.messageItem.message_author.id)
                if(res) {
                    this.$router.push({name: 'chat', query: {id: res.id}})
                    this.$store.commit('chat/SET_OPEN_DIALOG', res)
                }
            } catch(e) {
                this.$message.error(this.$t('error'))
            } finally {
                this.menuLoading = false
            }
        },
        createOrder() {
            if(this.messageItem.text) {
                this.injectContractor['comment'] = this.messageItem.text
            }

            this.injectContractorFilter = {
                profiles: this.messageItem.message_author.id
            }
            
            this.$nextTick(() => {
                if(this.$refs['orderDrawer']) {
                    this.$refs['orderDrawer'].toggleDrawer()
                }
            })
        },
        async createTask() {
            let visors = []
            if(this.activeChat.is_public) {
                try {
                    this.menuLoading = true
                    const {data} = await this.$http.get('/chat/member/list/', {
                        params: {
                            chat: this.activeChat.chat_uid
                        }
                    })
                    if(data && data.results.length) {
                        data.results.forEach(({user}) => {
                            visors.push(user)
                        })
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.menuLoading = false
                }
            }

            let form = {
                name: this.messageItem.text,
                operator: this.messageItem.chat_author,
                attachments: this.messageItem.attachments,
                reason: this.messageItem.message_uid
            }

            if(this.activeChat.workgroup) {
                if(!this.activeChat.workgroup.is_project) {
                    form.workgroup = {
                        name: this.activeChat.workgroup.name,
                        id: this.activeChat.workgroup.uid
                    }
                }
                if(this.activeChat.workgroup.is_project) {
                    form.project = {
                        name: this.activeChat.workgroup.name,
                        id: this.activeChat.workgroup.uid,
                        dead_line: this.activeChat.workgroup.dead_line || null,
                        date_start_plan: this.activeChat.workgroup.date_start_plan || null
                    }
                }
            }

            if(visors.length)
                form.visors = visors

            if(this.messageItem.text.length > 150) {
                if(this.activeChat.is_public)
                    form.name = this.$t('message_task', {chat: this.activeChat.name, user: this.messageItem.chat_author.full_name})
                else
                    form.name = this.$t('message_task', {chat: this.messageItem.chat, user: this.messageItem.chat_author ? this.messageItem.chat_author.full_name : this.activeChat.chat_author.full_name})

                form.description = this.messageItem.text
            } else
                form.name = this.messageItem.text

            eventBus.$emit('ADD_WATCH', {type: 'add_task', data: form})
        },
        toggleShow() {
            if(this.expand) {
                this.expand = false
                this.expandText = this.$t('chat.uncover')
            } else {
                this.expand = true
                this.expandText = this.$t('chat.collapse')
            }
            this.resizeEvent()
        },
        async deleteMessage() {
            try {
                const data = {chat_uid: this.activeChat.chat_uid, message_uid: this.messageItem.message_uid}
                this.$socket.client.emit('chat_delete_message', data)
                this.removeMessage(data)
            } catch(e) {
                this.$message.error(this.$t('error') + e)
            }
        },
        galleryInit() {
            this.$nextTick(() => {
                const lightboxWrap = this.$refs[`message_${this.messageItem.message_uid}`],
                    lightbox = lightboxWrap.querySelectorAll('.ch_lght')

                if(lightbox?.length) {
                    lightGallery(lightboxWrap, {
                        selector: ".ch_lght",
                        thumbnail: true,
                        animateThumb: true,
                        rotateLeft: true,
                        rotateRight: true,
                        actualSize: false,
                        flipHorizontal: false,
                        flipVertical: false,
                        fullScreen: this.isMobile ? false : true,
                        showThumbByDefault: this.isMobile ? false : true,
                        download: this.isMobile ? false : true,
                        speed: 300
                    })
                }
            })
        }
    },
    created(){
        if(this.messageItem.attachments?.length)
            this.galleryInit()
    }
}
</script>

<style lang="scss" scoped>
.bubble{
    &.mobile{
        -webkit-user-select: none; 
        -khtml-user-select: none; 
        -moz-user-select: none; 
        -ms-user-select: none; 
        user-select: none;
        transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
        &:not(.is_system){
            &.touch{
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
                transform: scale(0.97);
            }
        }
    }
}
</style>